import React from "react";
import map from "../../assets/images/icons/map.svg";
import email from "../../assets/images/icons/email.svg";
import phone from "../../assets/images/icons/phone.svg";
import fb from "../../assets/images/icons/facebook.png";
import twi from "../../assets/images/icons/twitter.png";
import wa from "../../assets/images/icons/whatsapp.png";
import { Slide } from "react-reveal";
import PageHero from "../reusables/hero/PageHero";

const MapBody = () => {
  return (
    <>
      <PageHero classes="dark-bg" titleClasses="title uppercase red" />
      <section className="section-spacing-50 no-padding-top">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content whiteBg width-960 box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="padding-16">
                  <div>
                    <div className="address-wrap flex">
                      <Slide left>
                        <div className="map">
                          <h3 className=" uppercase">Visit Us!</h3>
                          <div className="map-container width-100">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7595234825894!2d32.58130197389255!3d0.311056064036228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbafffe530157%3A0xa13b57f15c6a034e!2sBrand%20care%20Limited!5e0!3m2!1sen!2sug!4v1690279399678!5m2!1sen!2sug"
                              style={{ border: 0 }}
                              allowfullscreen="true"
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                          </div>
                        </div>
                      </Slide>
                      <Slide right>
                        <div className="address-office">
                          <div>
                            <div className="contacts-container">
                              <div className="contacts-item">
                                <div className="contacts-icon">
                                  <img src={map} alt="Map Icon" />
                                </div>
                                <div className="contacts-text">
                                  <h4>Office and Printery</h4>
                                  <p className=" margin-top-10">
                                    Printer's House, Nkrumah Road
                                  </p>
                                </div>
                              </div>
                              <div className="contacts-item">
                                <div className="contacts-icon">
                                  <img src={map} alt="Map Icon" />
                                </div>
                                <div className="contacts-text">
                                  <h4>Workshop</h4>
                                  <p className=" margin-top-10">
                                    Plot 17, Kibuga Namanve
                                  </p>
                                </div>
                              </div>
                              <div className="contacts-item">
                                <div className="contacts-icon">
                                  <img src={phone} alt="Call Icon" />
                                </div>
                                <div className="contacts-text">
                                  <h4>Phone</h4>
                                  <p className=" margin-top-10">
                                    +256 393 248 026
                                  </p>
                                  <p className=" margin-top-10">
                                    +256 200 905 483
                                  </p>
                                  <p className=" margin-top-10">
                                    +256 757 586 713
                                  </p>
                                </div>
                              </div>
                              <div className="contacts-item">
                                <div className="contacts-icon">
                                  <img src={email} alt="Email Icon" />
                                </div>
                                <div className="contacts-text">
                                  <h4>Email &amp; Post Office</h4>
                                  <p className=" margin-top-10">
                                    sales@brandcare.org
                                  </p>
                                  <p className=" margin-top-10">
                                    support@brandcare.org
                                  </p>
                                  <p className=" margin-top-10">
                                    Post office 75325, Clock Tower, Kampala,
                                    Uganda
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    </div>
                    <div className="address-message flex section-spacing-30">
                      <a href="mailto:sales@brandcare.org" className="button">
                        Get in Touch
                      </a>
                      <div className="social-icons flex">
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          <img src={twi} alt="WhatsApp Icon" />
                        </a>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          <img src={fb} alt="WhatsApp Icon" />
                        </a>
                        <a
                          href="https://wa.me/256772402260"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={wa} alt="WhatsApp Icon" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MapBody;
