import React from "react";
import { Slide } from "react-reveal";
import t1 from "../../assets/images/team/t1.jpeg";
import t2 from "../../assets/images/team/t2.png";
import t3 from "../../assets/images/team/t3.jpg";
import t4 from "../../assets/images/team/t4.png";
import t5 from "../../assets/images/team/t5.png";
import t6 from "../../assets/images/team/t6.jpg";
import t7 from "../../assets/images/team/t7.jpg";
import t8 from "../../assets/images/team/t8.jpg";
import t9 from "../../assets/images/team/t9.jpg";
import t10 from "../../assets/images/team/t10.jpg";
import t11 from "../../assets/images/team/t11.jpg";
import TeamCard from "../reusables/TeamCard";

const Team = () => {
  return (
    <section className="sections section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide top>
                <div className="section-head center">
                  <h3 className="text-shadow crimson uppercase">Dream Team</h3>
                </div>
              </Slide>
              <div className="section-body box-shadow border-radius-2">
                <ul className="team-container">
                  <TeamCard
                    pic={t10}
                    name="Jonathan Kakembo"
                    title="Director Business Development"
                  />
                  <TeamCard
                    pic={t2}
                    name="Maureen Mutesi"
                    title="Administration Manager"
                  />
                  <TeamCard
                    pic={t3}
                    name="Rebecca Ainomugisha"
                    title="Administration, People &amp; Culture"
                  />

                  <TeamCard
                    pic={t9}
                    name="Oonyu James Peter"
                    title="Senior Graphics Designer"
                  />
                  <TeamCard
                    pic={t5}
                    name="Raphael Ekanyu"
                    title="Production &amp; Client Services"
                  />
                  <TeamCard
                    pic={t4}
                    name="Sulaiman Aloikin"
                    title="Fleet &amp; Supply Chain"
                  />

                  <TeamCard pic={t1} name="Mansoor Khalid" title="Logistics" />
                  <TeamCard
                    pic={t6}
                    name="Majid Onanyang"
                    title="Installation &amp; Branding"
                  />
                  <TeamCard pic={t7} name="Joseph Mwesigye" title="Branding" />
                  <TeamCard
                    pic={t8}
                    name="Henry Mugode"
                    title="Branding Supervisor"
                  />
                  <TeamCard
                    pic={t11}
                    name="Isma Serunkuma"
                    title="Installation &amp; Branding"
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
