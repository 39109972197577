import React from "react";
import WeDoCard from "../reusables/WeDoCard";
import leaf from "../../assets/images/icons/leaf.svg";
import c1 from "../../assets/images/content/c1.jpg";
import c2 from "../../assets/images/content/c2.jpg";
import c3 from "../../assets/images/content/p4.jpg";

const PrintingBody = () => {
  return (
    <section className="section-spacing-50 no-padding-top">
      <div className="large">
        <div className="section-content-container">
          <div className="section-content whiteBg width-800 box-shadow relative margin-auto border-radius-2">
            <div className="section-body minus-margin-top-80">
              <div className="padding-16">
                <div className="we-do-container">
                  <WeDoCard service="Desk Calendars" image={c1} />
                  <WeDoCard service="Flyers &amp; Brochures" image={leaf} />
                  <WeDoCard service="Books" image={c2} />
                  <WeDoCard service="Notebooks" image={c3} />
                  <WeDoCard service="PVC Banners" image={leaf} />
                  <WeDoCard service="Annual Reports" image={leaf} />
                  <WeDoCard service="Billboard Skins" image={leaf} />
                  <WeDoCard service="Business Cards" image={leaf} />
                  <WeDoCard service="Gift Cards" image={leaf} />
                  <WeDoCard service="Vinyl Stickers" image={leaf} />
                </div>
                <div className="page-cta center section-spacing-30">
                  <p>We also offer Express Same Day printing of Business Cards, Brochures and Flyers.</p>
                </div>
                <div className="page-cta center section-spacing-30">
                  <a
                    href="#"
                    target="_blank"
                    className="button main-cta width-300 boldTitle"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrintingBody;
