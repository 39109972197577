import React from "react";

const HeroImgSlider = (props) => {
  return (
    <figure className="hero-slider-img">
      <img src={props.pic} alt={props.cap} />
      <figcaption>{props.cap}</figcaption>
    </figure>
  );
};

export default HeroImgSlider;
