import React from "react";
import { Helmet } from "react-helmet";
import PageHero from "../components/reusables/hero/PageHero";
import SignageBody from "../components/signage/SignageBody";

export default function Signage() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.BrandCare.org/signage" />
        <meta
          name="description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta
          property="og:description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta
          property="og:title"
          content="BRAND CARE LIMITED | SIGNAGE & FABRICATION"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1691206808/bcBanner_k5flvl.png"
        />
        <meta
          name="twitter:title"
          content="BRAND CARE LIMITED | SIGNAGE & FABRICATION"
        />
        <meta
          name="twitter:text:title"
          content="BRAND CARE LIMITED | SIGNAGE & FABRICATION"
        />
        <meta
          name="twitter:description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1691206808/bcBanner_k5flvl.png"
        />
        <title>BRAND CARE LIMITED | SIGNAGE & FABRICATION</title>
      </Helmet>
      <PageHero
        classes="dark-bg"
        titleClasses="title uppercase red"
        title="SIGNAGE & FABRICATION"
      />
      <SignageBody />
    </>
  );
}
