import React from "react";
import { Slide } from "react-reveal";
import PageHero from "../reusables/hero/PageHero";
import Mission from "../home/Mission";
import About from "../home/About";
import Team from "./Team";

const AboutBody = () => {
  return (
    <>
      <About />
      <Mission />
      <Team />
    </>
  );
};

export default AboutBody;
