import React from "react";
import { Slide } from "react-reveal";
import fb from "../../assets/images/icons/facebook.png";
import twi from "../../assets/images/icons/twitter.png";
import li from "../../assets/images/icons/linkedin.png";
import ig from "../../assets/images/icons/instagram.png";

const Footer = () => {
  return (
    <section className="section-spacing-50 no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content section-spacing-10">
              <Slide top>
                <div className="section-head center width-600 margin-auto">
                  <h3 className="text-shadow crimson uppercase">
                    FOLLOW US ON Social Media
                  </h3>
                </div>
              </Slide>
              <div>
                <ul className="footer-socials">
                  <li className=" button">
                    <a
                      href="instagram.com/brandcare_uganda"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={ig} alt="Brand care Instagram" />
                    </a>
                  </li>
                  <li className=" button">
                    <a
                      href="twitter.com/brandcareuganda"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={twi} alt="Brand care Twitter" />
                    </a>
                  </li>
                  <li className=" button">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      <img src={li} alt="Brand care LinkedIn" />
                    </a>
                  </li>
                  <li className=" button">
                    <a
                      href="facebook.com/brandcareuganda"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={fb} alt="Brand care Facebook" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="copyright margin-top-20 center black">
              <p>&copy; 2023 Brand Care Limited, All rights reserved</p>
            </div>
            <div className="designer section-spacing-10">
              <div className="design-inner center uppercase">
                <p>
                  Website designed by{" "}
                  <a href="https://ntuna.com" className="link">
                    NTUNA.COM
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
