import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import PageNotFound404 from "./pages/PageNotFound404";
import Printing from "./pages/Printing";
import Branding from "./pages/Branding";
import Signage from "./pages/Signage";
import MapBody from "./components/map/MapBody";
import WhyBody from "./components/why/WhyBody";
import About from "./pages/About";
//import Unpaid from "./pages/Unpaid";

export default class App extends React.PureComponent {
  render() {
    return (
      <Router>
        <div className="app" id="page">
          <a href="#content" className="skip-to-content-link">
            Skip to Content
          </a>
          <Header />
          <div className="site-content" id="content">
            <Switch>
              <Route exact path="/home">
                {/*  */}
                <Home />
              </Route>
              <Route exact path="/printing">
                {/* */}
                <Printing />
              </Route>
              <Route exact path="/branding">
                {/* <Branding />*/}
                <Branding />
              </Route>
              <Route exact path="/signage">
                {/* <Signage />*/}
                <Signage />
              </Route>
              <Route exact path="/map">
                {/*<MapBody /> */}
                <MapBody />
              </Route>
              <Route exact path="/why">
                {/*<WhyBody /> */}
                <WhyBody />
              </Route>
              <Route exact path="/about">
                {/* <About />*/}
                <About />
              </Route>
              <Route exact path="/404">
                {/* <PageNotFound404 />*/}
                <PageNotFound404 />
              </Route>

              <Route path="*">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}
