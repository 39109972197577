import React from "react";
import { Helmet } from "react-helmet";
import AboutBody from "../components/about/AboutBody";

export default function About() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.BrandCare.org/about" />
        <meta
          name="description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta
          property="og:description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta property="og:title" content="BRAND CARE LIMITED | ABOUT US" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1691206808/bcBanner_k5flvl.png"
        />
        <meta name="twitter:title" content="BRAND CARE LIMITED | ABOUT US" />
        <meta
          name="twitter:text:title"
          content="BRAND CARE LIMITED | ABOUT US"
        />
        <meta
          name="twitter:description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1691206808/bcBanner_k5flvl.png"
        />
        <title>BRAND CARE LIMITED | ABOUT US</title>
      </Helmet>

      <AboutBody />
    </>
  );
}
