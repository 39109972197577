import React from "react";
import { Slide } from "react-reveal";
import PageHero from "../reusables/hero/PageHero";
import stars from "../../assets/images/content/stars.svg";
import king from "../../assets/images/content/icon_1.png";
import client from "../../assets/images/content/client.svg";
import trusted from "../../assets/images/content/trusted.svg";

const WhyBody = () => {
  return (
    <>
      <PageHero classes="dark-bg" titleClasses="title uppercase red" />
      <section className="section-spacing-50 no-padding-top">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content whiteBg width-600 box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="padding-16">
                  <ul className="why-container">
                    <li>
                      <h3>
                        <img src={client} alt="Trophy Icon" />
                        <span>Client First</span>
                      </h3>
                      <p>
                        We strive to understand your needs and how we can help
                        you. Our design team comes up with design solutions
                        working hand in hand with you at no cost until the final
                        sample is approved for print/ production.
                      </p>
                    </li>
                    <li>
                      <h3>
                        <img src={trusted} alt="Trophy Icon" />
                        <span>Trusted</span>
                      </h3>
                      <p>
                        We have been trusted by Multinationals, Non governmental
                        Organisations, FMCGs, Government of Uganda, Insurance,
                        Financial Institutions and private individuals to make
                        their brand stand out.
                      </p>
                    </li>
                    <li>
                      <h3>
                        <img src={stars} alt="Trophy Icon" />
                        <span>We strive for Excellence</span>
                      </h3>
                      <p>
                        With our diverse team with a combined work experience of
                        more than 100 years, we nurture talent, fuel creativity
                        and this drives us towards innovative solutions which
                        then leads to our number one priority Customer
                        satisfaction.
                      </p>
                    </li>
                  </ul>
                  <div className="page-cta center section-spacing-30">
                    <a
                      href="#"
                      target="_blank"
                      className="button main-cta width-300 boldTitle capitalize"
                      rel="noopener noreferrer"
                    >
                      Tell us about your project
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyBody;
