import React from "react";

const TestimonialCard = (props) => {
  return (
    <div className="testim-card center">
      <div className="testim-text">
        <p>“{props.test}”</p>
      </div>
      <div className="testim-user">
        <h4>{props.testUser}</h4>
        <p>{props.testCompany}</p>
      </div>
      {/*
      <div className="testimonial-file">
        <a href={props.file} download>
          {" "}
          Download Recommendation
        </a>
      </div>
      */}
    </div>
  );
};

export default TestimonialCard;
