import React from "react";
import { Slide } from "react-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings2 } from "../reusables/testCarousel";
import user from "../../assets/images/icons/avatar.png";
import TestimonialCard from "../reusables/TestimonialCard";
import bouequity from "../../assets/files/recommends/bouequity.pdf";
import uba from "../../assets/files/recommends/uba.pdf";
import ahpc from "../../assets/files/recommends/ahpc.pdf";
import unbs from "../../assets/files/recommends/unbs.pdf";
import equity from "../../assets/files/recommends/equity.pdf";
import nda from "../../assets/files/recommends/nda.pdf";
import oppo from "../../assets/files/recommends/oppo.pdf";
import dfcu from "../../assets/files/recommends/dfcu.pdf";

const Testimonials = () => {
  return (
    <section className="sections section-spacing-100 cloudy-bg">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide top>
                <div className="section-head center width-600 margin-auto">
                  <h3 className="text-shadow crimson uppercase">
                    Our Partners' testimonies
                  </h3>
                </div>
              </Slide>
              <div className="section-body section-spacing-60">
                <div className="testimonials-container slider-items-container">
                  <Slider {...settings2}>
                    <TestimonialCard
                      test="Brand Care has supplied Bank of Uganda with branded diaries and other office consumables for more than 5 years and their perfomance has been satisfactory."
                      testCompany="Ag. Director, Bank of Uganda"
                      testUser="Claire Lwasa Nakawesi"
                      file={bouequity}
                    />
                    <TestimonialCard
                      test="Brand Care has been supplying us with annual calendars most recent being the ones we are currently using for 2023. Their calendars are of high quality with impressive print. Brand care's customer service is also outstanding with clear communication during the processs of working on any kind of work you can give them and they ensure prompt and efficient delivery of the specific items within the timelines agreed upon."
                      testCompany="Ag. Head Marketing & Corporate Communications, United Bank of Africa, Uganda"
                      testUser="Hoziana Niyonsaba"
                      file={uba}
                    />
                    <TestimonialCard
                      test="Brand Care have been and continue to provide printing and branding services to us. They are highly professional in their work and I highly recommend their services to you."
                      testCompany="Business Development & Marketing Department, Pride Micro Finance"
                      testUser="Micheal Tibagendeka"
                      file={unbs}
                    />
                    <TestimonialCard
                      test="Brand Care has provided National Drug Authority with Branded Diaries and Assorted printing material for the last three years to our satisfaction. Their turnaround time especially in prompt delivery is excellent."
                      testCompany="Secretary, National Drug Authority"
                      testUser="Davide Nahamya"
                      file={nda}
                    />
                    <TestimonialCard
                      test="We have had an opportunity to work with Brand Care Limited over the last two years. During this period, we have been satisfied with their professionalism, dedication, attention to detail and the entire team is solution oriented. They have and continue to provide Equity bank Limited with excellent and expertise in branding and printing strategies. It is with this experience that I recommend Brand Care Limited services to you."
                      testCompany="Marketing Department, Equity Bank Limited"
                      testUser="Patricia Biraaro"
                      file={equity}
                    />
                    <TestimonialCard
                      test="I confirm that Brand Care Limited has worked with Opportunity Bank to supply printing and promotional materials including calendars, training charts among others. I recommend Brand care for the same services to your organisation."
                      testCompany="Procurement & Administration Supervisor, Opportunity Bank"
                      testUser="Atuheire Georgia"
                      file={oppo}
                    />
                    <TestimonialCard
                      test="Brand Care is our service provider for the last 4 years and still giving good service. During this period, the company has executed its duties satisfactory, professionally and has demonstrated ethical behaviour as expected of them. We therefore have no hestitation in recommending them to you."
                      testCompany="Head of Procurement, DFCU Bank"
                      testUser="Stanslus Kabuyaga"
                      file={dfcu}
                    />
                    <TestimonialCard
                      test="Brand Care has been supplying us quality staff uniforms and therefore we recommend it for any opportunity to supply same items to you without reservation."
                      testCompany="Deputy Registrar, The Allied Health Professionals Council"
                      testUser="Nyamutale Peter"
                      file={ahpc}
                    />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
