import React from "react";
import { Slide } from "react-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings1 } from "../reusables/carousel";
import right from "../../assets/images/icons/long-arrow-right-white.svg";
import HeroImgSlider from "../reusables/HeroImgSlider";
import p1 from "../../assets/images/content/p1.jpg";
import profile from "../../assets/files/brand-care-company-profile.pdf";
import a42 from "../../assets/images/hero/42.jpg";
  /*
import a1 from "../../assets/images/hero/1.jpg";
import a2 from "../../assets/images/hero/2.jpg";
import a3 from "../../assets/images/hero/3.jpg";
import a4 from "../../assets/images/hero/4.jpg";
import a5 from "../../assets/images/hero/5.jpg";
import a6 from "../../assets/images/hero/6.jpg";
import a7 from "../../assets/images/hero/7.jpg";
import a8 from "../../assets/images/hero/8.jpg";
import a9 from "../../assets/images/hero/9.jpg";
import a10 from "../../assets/images/hero/10.jpg";
import a12 from "../../assets/images/hero/12.jpg";
import a13 from "../../assets/images/hero/13.jpg";
import a14 from "../../assets/images/hero/14.jpg";
import a15 from "../../assets/images/hero/15.jpg";
import a16 from "../../assets/images/hero/16.jpg";
import a17 from "../../assets/images/hero/17.jpg";
import a18 from "../../assets/images/hero/18.jpg";
import a19 from "../../assets/images/hero/19.jpg";
import a20 from "../../assets/images/hero/20.jpg";
import a21 from "../../assets/images/hero/21.jpg";
import a22 from "../../assets/images/hero/22.jpg";
import a23 from "../../assets/images/hero/23.jpg";
import a24 from "../../assets/images/hero/24.jpg";
import a25 from "../../assets/images/hero/25.jpg";
import a26 from "../../assets/images/hero/26.jpg";
import a27 from "../../assets/images/hero/27.jpg";
import a28 from "../../assets/images/hero/28.jpg";
import a29 from "../../assets/images/hero/29.jpg";
import a30 from "../../assets/images/hero/30.jpg";
import a31 from "../../assets/images/hero/31.jpg";
import a32 from "../../assets/images/hero/32.jpg";
import a34 from "../../assets/images/hero/34.jpg";
import a35 from "../../assets/images/hero/35.jpg";
import a36 from "../../assets/images/hero/36.jpg";
import a37 from "../../assets/images/hero/37.jpg";
import a38 from "../../assets/images/hero/38.jpg";
import a39 from "../../assets/images/hero/39.jpg";
import a40 from "../../assets/images/hero/40.jpg";
import a41 from "../../assets/images/hero/41.jpg";
import a43 from "../../assets/images/hero/43.jpg";

*/



const HomeHero = () => {
  return (
    <section className="hero cloudy-bg section-spacing-150">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="hero-content-container">
                <div className="hero-text-side">
                  <div className="hero-text">
                    <Slide top>
                      <h1>
                        Your Trusted Printing, Branding and Signage Partner
                      </h1>
                    </Slide>
                    <Slide left>
                      <p>
                        We offer quality printing, branding and signage
                        solutions to make your organisational brand stand out of
                        the crowd.
                      </p>
                    </Slide>
                  </div>
                  <div className="hero-cta">
                    <a
                      href="mailto:sales@brandcare.org"
                      className="button boldTitle main-cta"
                    >
                      <span>Talk To Us</span>
                      <img src={right} alt="Right Arrow" />
                    </a>
                    <a
                      href={profile}
                      download
                      className="button boldTitle sub-cta"
                    >
                      <span>Download Our Profile</span>
                    </a>
                  </div>
                </div>
                <div className="hero-img-side">
                  <div className="carous">
                    <div className="slider-items-container center-slide-arrows hero-slider-container">
                      <Slider {...settings1}>
                        <HeroImgSlider
                          pic={a42}
                          cap="Quality Digital Printing"
                        />
                        <HeroImgSlider
                          pic={p1}
                          cap="Quality Offset Printing in A4, A3, A2 sizes"
                        />
                        {/*
                        
                        <HeroImgSlider pic={a26} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a20} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a4} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a5} cap="Branded Merchandize" />

                        <HeroImgSlider pic={a1} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a2} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a3} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a14} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a6} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a7} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a8} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a9} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a10} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a12} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a15} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a16} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a17} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a13} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a18} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a19} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a21} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a22} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a23} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a24} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a25} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a27} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a28} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a29} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a30} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a31} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a32} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a34} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a35} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a36} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a37} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a38} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a39} cap="Branded Merchandize" />
                        <HeroImgSlider pic={a40} cap="Machines" />
                        <HeroImgSlider pic={a41} cap="Machines" />
                        <HeroImgSlider pic={a43} cap="Machines" />
                        
                        */}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
