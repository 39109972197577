import React from "react";
import { Slide } from "react-reveal";
import about1 from "../../assets/images/hero/aw.jpg";
import about2 from "../../assets/images/hero/aw2.jpg";
import about3 from "../../assets/images/hero/bc1.jpg";

const About = () => {
  return (
    <section className="about whitedot-bg section-spacing-150 no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="shared-section flex section-spacing-80 no-padding-top">
                <Slide left>
                  <div className="images-side flex">
                    <img
                      src={about1}
                      className="box-shadow"
                      alt="About Image"
                    />
                    <img
                      src={about2}
                      className="box-shadow"
                      alt="About Image"
                    />
                    <img
                      src={about3}
                      alt="About Image"
                      className="about-big-img box-shadow"
                    />
                  </div>
                </Slide>
                <div className="text-side">
                  <Slide bottom>
                    <div className="text-side-text">
                      <h3 className=" section-spacing-20">ABOUT BRAND CARE</h3>
                      <p>
                        Brand Care Limited was established in March 2015 with
                        just one laptop. We made a commitment to investing on
                        behalf of our customers and have since expanded.
                      </p>
                      <p className=" margin-top-10">
                        We offer a range of quality printing, branding, and
                        signage solutions supported by skilled personnel with a
                        combined and cumulative work experience of over 100
                        years.
                      </p>
                      <p className=" margin-top-10">
                        We are your{" "}
                        <strong className="boldTitle">
                          ONE STOP PRINTING HOUSE
                        </strong>{" "}
                        making us a trusted partner to get your project
                        completed.
                      </p>
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

{
  /*

<div className="text-side-text">
                      <p>
                        Brand Care Limited was established in March 2015 with
                        just one laptop. We made a commitment to investing on
                        behalf of our customers and have since expanded. We are
                        incorporated in Uganda and South Sudan and located at
                        Printer's House, Nasser Road, Kampala Uganda.{" "}
                      </p>
                      <p className=" margin-top-10">
                        We offer a range of quality printing, branding, amd
                        signage solutions supported by skilled personnel with a
                        combined and cumulative work experience of over 100
                        years. 
                      </p>
                      <p className=" margin-top-10">
                        We do digital, offset and large format printing. That
                        includes calendars, notebooks, books, annual reports,
                        billbaord skins, PVC banners, busines & greeting cards,
                        flyer, brochures, Vinyl stickers etc.
                      </p>
                      <p className=" margin-top-10">
                        We offer a wide variety of personalized merchandize and
                        products that can be used in company promotions,
                        campaigns and activations. They can also be used as
                        personalized gifts to your loved ones for special
                        occasions of their lives. For example personalized mugs,
                        wrist watches, wall clocks, t-shirts, key holders etc.
                      </p>
                      <p className=" margin-top-10">
                        We do can branding and can handle your project from
                        concept to final output. This includes merchandize
                        branding, vehicle branding, office branding, business
                        place branding etc.
                      </p>
                      <p className=" margin-top-10">
                        Through our aluminium,steel and wood signage fabrication
                        arm of over 80 years experience and cutting edge
                        technology of laser engraving, we do petrol station
                        pylon signs, shop signage, directional signs, ABC signs,
                        road side kiosks, neon & led signs, 3D signs, office
                        partitioning, warehouse fabrication etc.
                      </p>
                      <p className=" margin-top-10">
                        Partner with us to Ignite your brand. Whether you are
                        just starting up and eager to generate much needed brand
                        awareness or you are established and now want to
                        increase your sales and affirm your reach, promotional
                        products are one of the effective way to achieve this.
                      </p>
                      <p className=" margin-top-10">
                        We have done printing and branding work for big and
                        small companies and organizations in the region and the
                        world at large including World Health Organaization
                        (WHO), East African Crude Oil Pipeline (EACOP), Uganda
                        Revenue Authority (URA), Equity bank, Stanbic bank, Post
                        bank, Pride Microfinance, Opportunity bank, DFCU bank,
                        Case Hospital, Copia, Eskom, UAP Insurance, Private
                        Sector Foundation of Uganda (PSFU) etc.
                      </p>
                    </div>
*/
}
