import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import logo from "../../assets/images/logo/logo.PNG";
import whatsAppIcon from "../../assets/images/icons/whatsapp.svg";
import bars from "../../assets/images/icons/bars.svg";
import close from "../../assets/images/icons/close.svg";

{
  /*
const { location } = this.props;

const homeClass = location.pathname.match(/^\/home/) ? "active" : "";
const whyClass = location.pathname.match(/^\/why/) ? "active" : "";
const mapClass = location.pathname.match(/^\/map/) ? "active" : "";
const aboutClass = location.pathname.match(/^\/about/) ? "active" : "";

*/
}

const Header = () => {
  const [showParent, setShowParent] = useState(false);
  return (
    <header className="site-header">
      <div className="container">
        <div className="large">
          <div className="header-contents">
            <div className="site-brand">
              <a href="/" className="brand-logo block">
                <img src={logo} alt="Brand care logo" />
              </a>
            </div>
            <nav className="site-nav">
              <div className="menu-container">
                <label
                  onClick={() => setShowParent(true)}
                  htmlFor="toggle-responsive-menu"
                  className="nav-label bold-title"
                >
                  <img src={bars} alt="Menu" />
                </label>
                <input type="checkbox" id="toggle-responsive-menu" />
                <div
                  onClose={() => setShowParent(false)}
                  showParent={showParent}
                  className={`nav-inner ${showParent ? "showParent" : ""}`}
                >
                  <div className="hide-menu">
                    <button
                      onClick={() => setShowParent(false)}
                      className="hide-menu-btn button"
                    >
                      <img src={close} alt="Close Menu" />
                    </button>
                  </div>
                  <ul
                    className="site-menu"
                    onClick={() => setShowParent(false)}
                  >
                    <li>
                      <ul className="site-menu-inner">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/about">About Us</a>
                        </li>
                        {/*
                    <li className="dropdown-menu">
                      <a className="dropdown-trigger">What We Do</a>

                      <ul className="dropdown" id="about-menu">
                        <li>
                          <a href="/printing">
                            <p className="boldTitle">Printing</p>
                            <span>
                              Calendars, notebooks, books, annual reports,
                              billbaord skins, PVC banners, busines &amp;
                              greeting cards, flyer, brochures, Vinyl stickers
                              etc
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="/branding">
                            <p className="boldTitle">Branding </p>
                            <span>
                              Merchandize branding, vehicle branding, office
                              branding, business place branding etc.
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="/signage">
                            <p className="boldTitle">Signage</p>
                            <span>
                              Petrol station pylon signs, shop signage,
                              directional signs, ABC signs, road side kiosks,
                              neon &amp; led signs, 3D signs, office
                              partitioning, warehouse fabrication etc.
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    */}
                        <li>
                          <a href="/why">Why Us</a>
                        </li>
                        <li>
                          <a href="/map">Contact Us</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-cta whatsapp-button button">
                      <a href="https://wa.me/256757586713" title="WhatsApp Us">
                        <img src={whatsAppIcon} class="icon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default withRouter(Header);
