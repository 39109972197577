import React from "react";
import leaf from "../../assets/images/icons/leaf.svg";
import WeDoCard from "../reusables/WeDoCard";

const BrandingBody = () => {
  return (
    <section className="section-spacing-50 no-padding-top">
      <div className="large">
        <div className="section-content-container">
          <div className="section-content whiteBg width-800 box-shadow relative margin-auto border-radius-2">
            <div className="section-body minus-margin-top-80">
              <div className="padding-16">
                <div className="we-do-container">
                  <WeDoCard service="Merchandize" image={leaf} />
                  <WeDoCard service="Office Branding" image={leaf} />
                  <WeDoCard service="Workplace Branding" image={leaf} />
                  <WeDoCard service="A Frame Banners" image={leaf} />
                  <WeDoCard service="Tear Drop Banners" image={leaf} />
                  <WeDoCard service="Spinner Banners" image={leaf} />
                  <WeDoCard service="Tyre Covers" image={leaf} />
                  <WeDoCard service="Truck branding" image={leaf} />
                  <WeDoCard service="Outdoor Tents" image={leaf} />
                  <WeDoCard service="Pullup Banners" image={leaf} />
                  <WeDoCard service="Popup Counter" image={leaf} />
                </div>
                <div className="page-cta center section-spacing-30">
                  <a
                    href="#"
                    target="_blank"
                    className="button main-cta width-300 boldTitle"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrandingBody;
