import React from "react";
import { Helmet } from "react-helmet";
import Clients from "../components/home/Clients";
import HomeHero from "../components/home/HomeHero";
import Testimonials from "../components/home/Testimonials";
import WhatWeDo from "../components/home/WhatWeDo";

export default function Home() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.BrandCare.org/" />
        <meta
          name="description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta
          property="og:description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta
          property="og:title"
          content="BRAND CARE LIMITED | PRINTING & BRANDING COMPANY IN UGANDA, EAST AFRICA"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1691206808/bcBanner_k5flvl.png"
        />

        <meta
          name="twitter:title"
          content="BRAND CARE LIMITED | PRINTING & BRANDING COMPANY IN UGANDA, EAST AFRICA"
        />
        <meta
          name="twitter:text:title"
          content="BRAND CARE LIMITED | PRINTING & BRANDING COMPANY IN UGANDA, EAST AFRICA"
        />
        <meta
          name="twitter:description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1691206808/bcBanner_k5flvl.png"
        />
        <title>
          BRAND CARE LIMITED | PRINTING & BRANDING COMPANY IN UGANDA, EAST
          AFRICA
        </title>
      </Helmet>
      <HomeHero />
      <WhatWeDo />
      <Clients />
      <Testimonials />
    </>
  );
}
