import React from "react";

const WeDoCard = (props) => {
  return (
    <div className="we-do-item">
      <h4>{props.service}</h4>
    </div>
  );
};

export default WeDoCard;
