import React from "react";
import { Helmet } from "react-helmet";
import BrandingBody from "../components/branding/BrandingBody";
import PageHero from "../components/reusables/hero/PageHero";

export default function Branding() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.BrandCare.org/branding" />
        <meta
          name="description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta
          property="og:description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta
          property="og:title"
          content="BRAND CARE LIMITED | BRANDING SERVICES"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1691206808/bcBanner_k5flvl.png"
        />
        <meta
          name="twitter:title"
          content="BRAND CARE LIMITED | BRANDING SERVICES"
        />
        <meta
          name="twitter:text:title"
          content="BRAND CARE LIMITED | BRANDING SERVICES"
        />
        <meta
          name="twitter:description"
          content="Brand Care Limited has over 150 years of experience in printing and branding works. We are the region's biggest printing and branding company."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1691206808/bcBanner_k5flvl.png"
        />
        <title>BRAND CARE LIMITED | BRANDING SERVICES</title>
      </Helmet>
      <PageHero
        classes="dark-bg"
        titleClasses="title uppercase red"
        title="BRANDING SERVICES"
      />
      <BrandingBody />
    </>
  );
}
