import React from "react";
import { Slide, Fade } from "react-reveal";
import print from "../../assets/images/icons/print.svg";
import design from "../../assets/images/icons/design.svg";
import sign from "../../assets/images/icons/sign.svg";
import right from "../../assets/images/icons/long-arrow-right-white.svg";

const WhatWeDo = () => {
  return (
    <section className="about black-bg section-spacing-80">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide top>
                <div className="section-head center width-600 margin-auto">
                  <h3 className="text-shadow crimson uppercase">
                    WE OFFER QUALITY SERVICES
                  </h3>
                </div>
              </Slide>
              <div className="section-body section-spacing-60">
                <div className="what-items-container">
                  <Slide left>
                    <div className="what-card">
                      <img src={print} alt="Brand care Printing services" />
                      <h3>Printing</h3>
                      <p>We offer digital, offset and large format printing.</p>
                      <a href="/printing">
                        <h5>See More</h5>
                        <img src={right} alt="Right Arrow" />
                      </a>
                    </div>
                  </Slide>

                  <Slide top>
                    <div className="what-card">
                      <img src={design} alt="Brand care Branding services" />
                      <h3>Branding</h3>
                      <p>We offer innovative branding solutions for your business.</p>
                      <a href="/branding">
                        <h5>See More</h5>
                        <img src={right} alt="Right Arrow" />
                      </a>
                    </div>
                  </Slide>
                  <Slide right>
                    <div className="what-card">
                      <img src={sign} alt="Brand care Signage services" />
                      <h3>Signage</h3>
                      <p>
                        We offer signage solutions to put your brand above the
                        crowd.
                      </p>
                      <a href="/signage">
                        <h5>See More</h5>
                        <img src={right} alt="Right Arrow" />
                      </a>
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
