import React from "react";
import leaf from "../../assets/images/icons/leaf.svg";
import WeDoCard from "../reusables/WeDoCard";

const SignageBody = () => {
  return (
    <section className="section-spacing-50 no-padding-top">
      <div className="large">
        <div className="section-content-container">
          <div className="section-content whiteBg width-800 box-shadow relative margin-auto border-radius-2">
            <div className="section-body minus-margin-top-80">
              <div className="padding-16">
                <div className="we-do-container">
                  <WeDoCard service="Pylon Signs" image={leaf} />
                  <WeDoCard service="Shop Signage" image={leaf} />
                  <WeDoCard service="Directional Signs" image={leaf} />
                  <WeDoCard service="Directional Signs" image={leaf} />
                  <WeDoCard service="ABC Signs" image={leaf} />
                  <WeDoCard service="Roadside Kiosks" image={leaf} />
                  <WeDoCard service="Neon&amp;Led Signs" image={leaf} />
                  <WeDoCard service="3D&amp;2D Signs" image={leaf} />
                  <WeDoCard service="Office Partitioning" image={leaf} />
                  <WeDoCard service="Warehouse Fabrication" image={leaf} />
                </div>
                <div className="page-cta center section-spacing-30">
                  <a
                    href="#"
                    target="_blank"
                    className="button main-cta width-300 boldTitle"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignageBody;
