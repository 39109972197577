import React from "react";
import { Slide } from "react-reveal";
import clients from "../../assets/images/content/clients.jpeg";

const Clients = () => {
  return (
    <section className="sections section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide top>
                <div className="section-head center width-600 margin-auto">
                  <h3 className="text-shadow crimson uppercase">
                    OUR PARTNERS ALONG THIS AWESOME JOURNEY
                  </h3>
                </div>
              </Slide>
              <div className="section-body section-spacing-60 no-padding-bottom width-800 margin-auto">
                <div className="clients-container">
                  <Slide bottom>
                    <img src={clients} alt="brand care clients" />
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
